@import "../../../theme.less";
.carousel {
  position: relative;
}

.arrowIcon {
  font-size: 40px;
  position: absolute;
  cursor: pointer;
}

.arrowLeft {
  .arrowIcon();
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  margin-left: 10px;
}

.arrowRight {
  .arrowIcon();
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  margin-right: 10px;
}
