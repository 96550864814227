@import "../../../theme.less";

.row {
  color: @white;
}

.name {
  font-size: 1.1em;
  font-weight: bold;
}

a.footerLink:link {
  color: @white;
  text-decoration: none;
}

a.footerLink:visited {
  color: @white;
  text-decoration: none;
}

a.footerLink:hover {
  color: @white;
  text-decoration: none;
}

a.footerLink:active {
  color: @white;
  text-decoration: none;
}

@media only screen and (max-width: @sm) {
  .row {
    text-align: center;
  }

  .col {
    margin-top: 24px;
  }
}
