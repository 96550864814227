@import "../../theme.less";

.textPrimaryColor {
  color: @primary-color;
}

.textCenter {
  text-align: center;
}

.registerButton {
  min-width: 16em;
  margin-bottom: 1em;
}
