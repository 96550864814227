@import "../../../theme.less";

.agreementFooter {
  // margin-bottom: 8px;
  padding: 8px;
  width: 100%;
  // border-radius: 4px;
  color: @white;
  position: fixed;
  z-index: 100;
  bottom: 0px;
  background: linear-gradient(0deg, rgba(102, 187, 158, 1) 0%, rgba(102, 187, 158, 0.8) 75%, rgba(255, 255, 255, 0) 100%)
}

.agreementFooterContent {
  max-height: 256px;
  overflow-y: scroll;
}

.cookieTitle {
  font-size: 1.25em;
  font-weight: 500;
}

.cookieDetail {
  font-weight: 200;
}

.cookieHeader {
  padding: 16px 24px;
  // color: #000000d9;
  // background: @white;
  // border-bottom: 1px solid rgba(0, 0, 0, .06);
  // border-radius: 2px 2px 0 0;


  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, .06);
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  pointer-events: auto;
}

.agreementFooter h4 {
  color: @white;
}

.cookieFooter {
  text-align: center;
  box-sizing: border-box;
  padding: 0 0 24px;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  pointer-events: none;
  position: fixed;
  z-index: 100;
  // top: 100px;

  max-width: calc(100vw - 32px);
  margin: 0 auto;
  bottom: 20px;
}

.cookieContent {
  padding: 16px 24px;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  pointer-events: auto;
}

.cookieSetting {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.cookieSection {
  // border-radius: 4px;
  border-bottom: 1px solid @grey-3;
  margin-top: 8px;
  padding: 8px;
  align-items: center;
}

.cookieInline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  .modalBtn {
    font-size: 16px;
    width: 143px;
    height: 40px;
  }
}

.serviceContent {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 100px;

  @media only screen and (max-width: @lg) {
    margin-top: 60px;
  }
}

.animateBottom {
  // position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 2s;
  animation-name: animatebottom;
  animation-duration: 2s
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0
  }

  to {
    bottom: 0px;
    opacity: 1
  }
}

@keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

.selectWrapper {
  .selectWrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    padding: 0px 24px;

    @media only screen and (max-width: 767px) {
      position: relative;
      flex-direction: column;
    }

    .selectCustom,
    .selectCustomActive {
      min-width: 121px;
      margin-right: 16px;

      @media only screen and (max-width: 767px) {
        max-width: calc(100% - 48px);
        margin: 0 auto;
        margin-bottom: 16px;
      }

      :global {
        .ant-select-item {
          width: 213px;
          font-size: 18px;
          padding: 9px 12px;
          text-align: left;
        }

        .ant-select-item-option-active {
          background-color: #daf4ed;
        }

        .ant-select-item-option-selected {
          background-color: #daf4ed;
          color: #66bb9e;
        }

        .ant-select-selection-placeholder {
          color: @black-2;
          opacity: 1;
        }

        .ant-select-arrow {
          color: @grey-2;
          font-size: 16px;
          margin-top: -8px;
          opacity: 1;
        }
      }
    }

    .selectCustomActive {
      :global {
        .ant-select-selector {
          color: white;
          background-color: #66bb9e;
        }

        .ant-select-arrow {
          color: white;
          font-size: 16px;
          margin-top: -8px;
        }
      }
    }
  }
}

.ant-modal-mask {
  background: transparent;
  pointer-events: none;
}

.ant-modal-wrap {
  pointer-events: none;
}

.ant-modal {
  pointer-events: all;
}