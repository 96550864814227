@import "../../../../theme.less";

.container {
  position: absolute;
  top: 64px;
  right: 0;
  height: 0;
  width: 0;
  background-color: @light-grey-2;
  z-index: 2;
  transition: width 0.2s;
}

.open {
  min-height: calc(100vh - 64px);
  width: 256px;
  max-width: 100vw;
}
