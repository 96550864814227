@import "../../theme.less";

.frontPageWrapper {
  margin: 0px 48px;

  @media only screen and (max-width: @sm) {
    margin: 0px 24px;
  }
}

.frontPageDetail {
  font-size: 16px;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 500;
}

.button {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
}

.bannerWrapper {
  max-width: 1141px;
  max-height: 440px;
  margin: 0 auto;

  .bannerImg {
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
    cursor: pointer;
  }

  @media only screen and (max-width: @md) {
    width: auto;
    margin: 0px -48px;
  }

  @media only screen and (max-width: @sm) {
    width: auto;
    margin: 0px -24px;
  }
}

.header {
  margin: 0 auto 48px auto;
  text-align: center;
  max-width: 890px;
  .headerTitle {
    margin-top: 48px;
    font-size: 32px;
    font-weight: 600;

    @media only screen and (max-width: @sm) {
      font-size: 24px;
      margin-top: 32px;
    }
  }

  .headerDetail {
    margin-top: 8px;
    font-size: 20px;

    @media only screen and (max-width: @xs) {
      font-size: 16px;
    }
  }
}

.navigationWrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  margin-bottom: 20px;

  .IconDown {
    cursor: pointer;
  }

  @media only screen and (max-width: @sm) {
    display: none;
  }
}

.mainBackground {
  margin: 0px -48px;
  @media only screen and (max-width: @sm) {
    margin: 0px -24px;
  }
  img {
    width: 100%;
  }
}

.mainTopBg {
  .mainBackground();
}

.mainBottomBg {
  .mainBackground();
}

.serviceWrapper {
  margin: 0px -48px;
  background-color: @light-pink;
  padding: 60px 24px 30px 24px;
  text-align: center;

  @media only screen and (max-width: @lg) {
    padding: 50px 24px 20px 24px;
  }

  @media only screen and (max-width: @sm) {
    margin: 0px -24px;
  }

  @media only screen and (max-width: @xs) {
    padding-bottom: 60px;
  }

  .serviceContent {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 100px;

    @media only screen and (max-width: @lg) {
      margin-top: 60px;
    }
  }

  .serviceRow {
    display: flex;
    justify-content: center;
  }

  .serviceCol {
    margin-bottom: 72px;
    @media only screen and (max-width: @md) {
      margin-bottom: 40px;
    }

    @media only screen and (max-width: @md) {
      margin-bottom: 40px;
    }

    @media only screen and (max-width: @xs) {
      margin-bottom: 9px;
    }
  }
}

.mentalHealthWrapper {
  margin: 10px auto 240px auto;
  text-align: center;
  max-width: 1250px;

  @media only screen and (max-width: @md) {
    margin-top: 50px;
    margin-bottom: 200px;
  }

  @media only screen and (max-width: @sm) {
    margin-bottom: 148px;
  }

  .mentalHealthImage {
    width: 100%;
    max-width: 573px;
    @media only screen and (max-width: @sm) {
      max-width: 500px;
    }
  }

  .mentalRow {
    margin-top: 90px;
    @media only screen and (max-width: @lg) {
      margin-top: 60px;
    }
  }

  .mentalContent {
    text-align: left;

    @media only screen and (max-width: @sm) {
      margin-top: 48px;
    }
  }

  .goJourney {
    margin-top: 48px;
    .button();
    @media only screen and (max-width: @sm) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.infoModalWrapper {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;

  .modalHeader {
    font-size: 18px;
    font-weight: 600;
  }
  .modalDetail {
    font-size: 16px;
    margin-top: 8px;
  }

  .counselor {
    font-size: 16px;
    margin: 8px 0px;
  }

  .modalFooter {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    .modalBtn {
      font-size: 16px;
      width: 143px;
      height: 40px;
    }
  }
}