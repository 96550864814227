@import "../../../theme.less";

.header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px 0px @grey-2;
  z-index: 3;
  position: fixed;
  width: 100%;
}

.container {
  align-self: center;
  margin-top: 64px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  max-width: 768px;
}

@media only screen and (max-width: 800px) {
  // For tablets
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
}
