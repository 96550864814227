@import "../../../theme.less";

.agreementCard {
  max-height: 256px;
  overflow-y: scroll;
  background-color: @light-grey-2;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid @grey-3;

  h1 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }
}

.agreementRequiredText {
  color: @primary-color;
}

.cookieTextSection {
  margin-bottom: 16px;
}