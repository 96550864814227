@import "../../../theme.less";

.bar {
  position: absolute;
  width: 24px;
  height: 0;
  background-color: @primary-color;
  border: 2px solid @primary-color;
  border-radius: 4px;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform -webkit-opacity;
  -moz-transition-property: -moz-transform -moz-opacity;
  -o-transition-property: -o-transform -o-opacity;
  transition-property: transform opacity;
}

.container {
  cursor: pointer;
  position: relative;
  height: 20px;
  width: 24px;

  .bar:nth-child(1) {
    top: 0px;
  }

  .bar:nth-child(2) {
    top: 8px;
  }

  .bar:nth-child(3) {
    top: 16px;
  }
}

.open {
  .bar:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
  }

  .bar:nth-child(2) {
    opacity: 0;
  }

  .bar:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-225deg);
    -ms-transform: rotate(-225deg);
    transform: rotate(-225deg);
  }
}
