@import "../../../theme.less";

.serviceCard {
  width: 100%;
  max-width: 253px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  padding: 32px 24px;

  @media only screen and (max-width: @sm) {
    padding: 24px 16px;
  }

  .serviceImg {
    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
    }
  }

  .serviceTitle {
    font-size: 18px;
    font-weight: 500;
    margin-top: 32px;

    @media only screen and (max-width: @xs) {
      font-size: 16px;
    }
  }

  .serviceDescription {
    font-size: 16px;
    margin-top: 16px;

    @media only screen and (max-width: @md) {
      font-size: 14.8px;
    }

    @media only screen and (max-width: @xs) {
      font-size: 14px;
    }

    @media only screen and (max-width: 475px) {
      font-size: 12px;
    }
  }
}
.serviceCard:hover {
  // margin-top: -20px;
  transform: translateY(-10%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
