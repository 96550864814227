@import "../../../theme.less";

.header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px 0px @grey-2;
  // overflow-x: auto;
  z-index: 3;
  position: fixed;
  width: 100%;
}

.container {
  margin-top: 64px;
  align-self: center;
  width: 100%;
}
