@import "../../../theme.less";

.alignRight {
  margin-left: auto;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

.spaceAround {
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  display: flex;
}

.spaceAroundLast {
  padding-left: 12px;
  align-items: center;
  display: flex;
}

.mobileMenu {
  display: none;
}

.avatar {
  cursor: pointer;

  &:hover {
    border: 1px solid @primary-color;
    box-shadow: @box-shadow-base;
  }
}

.profileImage {
  cursor: pointer;

  max-width: 32px;
  border: 2px solid @light-grey-1;
  border-radius: 4px;

  &:hover {
    border: 2px solid @primary-color;
    box-shadow: @box-shadow-base;
  }
}

.language {
  cursor: pointer;
  font-size: large;
}

@media only screen and (max-width: @md) {
  .menu {
    display: none;
  }

  .mobileMenu {
    display: block;
  }
}

.language {
  font-size: 1em;
}
