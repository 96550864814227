@import "../../../theme.less";

.logo {
  color: @primary-color;
  font-weight: bold;
  font-size: 1.75em;
  line-height: 80%;
  position: relative;
}

.decorate {
  position: absolute;
  color: @white;
  right: -12px;
  bottom: -12px;
  font-weight: normal;
  font-size: 10px;
  height: 16px;
  border-radius: 8px;
  box-shadow: @box-shadow-base;
  padding-left: 4px;
  padding-right: 4px;
}

@media only screen and (max-width: @md) {
  .logo {
    font-size: 1.5em;
  }

  .decorate {
    bottom: -12px;
    right: 0;
  }
}
